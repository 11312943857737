import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Condition from "../components/Condition";

const translations = {
  en: {
    spinalPain: 'Spinal Pain',
    spinalPainDescription: 'The spine is the central axis of the human body and conduit of the central nervous system. Its normal functioning is intimately related to overall health. Chiropractic treats common pain conditions arising from spinal joint dysfunctions and their associated disorders, thereby helping to attain wellness.',
    otherPain: 'Peripheral and Joint Pain',
    otherPainDescription: 'The upper and lower limbs have a close biomechanical relationship with the spine and are supplied by the peripheral nervous system. Chiropractic takes a wholistic approach which analyses and treats the peripheral joints and neuromusculosketal system to improve overall health.'
  },
  ch: {
    spinalPain: '脊椎痛症',
    spinalPainDescription: '脊柱是人體的中心支柱，更是中樞神經系統的管道。它的正常運作與整個身體健康息息相關。脊骨神經醫學集中分析由脊柱功能性障礙所引發的普遍痛症及相關症狀，加以針對性的治療，從而改善整體健康。',
    otherPain: '其他痛症',
    otherPainDescription: '從脊柱伸𨒂出來的上下肢體，在生物力學上與脊柱有着緊密的互動協調作用，並且由週邊神經系統支配。脊骨神經醫學從整全的角度，分析並治療脊柱週邊的骨骼、肌腱與神經系統，從而改善整體健康。'
  }
}

const AllConditionsTemplate = ({ pageContext, data }) => {

  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
        }
      ]
    }}>
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]['headerTitle']}
        imageUrl={data.firestore.page.content.headerImage}
      />
      <main className="my-12 flex flex-col justify-center items-center px-0 lg:px-16">
        <h1 className="text-coconut font-semibold text-2xl lg:text-3xl">{translations[pageContext.locale]['spinalPain']}</h1>
        <hr className="border-mango border w-8 my-6"/>
        <p className="text-brick text-base lg:text-xl text-center max-w-5xl mx-8">{translations[pageContext.locale]['spinalPainDescription']}</p>
        <div className="my-8 max-w-7xl w-full grid gap-8 grid-cols-1 lg:grid-cols-2">
          {
            data.firestore.conditions.filter(item => item.type==='spinal').map(item => (
              <Condition key={item.id} item={item} />
            ))
          }
        </div>
        <h1 className="text-coconut font-semibold text-2xl lg:text-3xl">{translations[pageContext.locale]['otherPain']}</h1>
        <hr className="border-mango border w-8 my-6"/>
        <p className="text-brick text-base lg:text-xl text-center max-w-5xl mx-8">{translations[pageContext.locale]['otherPainDescription']}</p>
        <div className="my-8 max-w-7xl w-full grid gap-8 grid-cols-1 lg:grid-cols-2">
          {
            data.firestore.conditions.filter(item => item.type==='other').map(item => (
              <Condition key={item.id} item={item} />
            ))
          }
        </div>
      </main>
    </Layout>
  )
}

export default AllConditionsTemplate

export const query = graphql`
  query AllConditionsTemplateQuery($locale: String! $pageId: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
      conditions(locale: $locale) {
        id
        title
        href
        type
        description
        thumbnail
      }
    }
  }
`